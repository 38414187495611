








































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {GetMyRemindersOnDateRange} from "../../graphql/auth";
import {ReminderPaginator} from "@/typescript/types";
import SmartModal from "@/components/general/SmartModal.vue";
import WidgetTemplate from "@/components/general/WidgetTemplate.vue";
import CreateReminder from "@/components/user/CreateReminder.vue";
import {addDays, format, isBefore, isFuture, isToday} from "date-fns";
@Component({
  components: {WidgetTemplate, SmartModal}
})
export default class DashboardReminder extends Vue {

  reminderLists: ReminderPaginator | null = null;
  page: number = 0;
  @Prop({required: false, default: null})
  startDate!: Date

  @Prop({required: false, default: null})
  endDate!: Date

  @Prop({required: false, default: null})
  userId!: String

  @Prop({required: false, default: false})
  inModal!: boolean;

  selfModalOpen() {
    // animation: 'animated fadeInLeft faster',
    this.$buefy.modal.open({
      component: DashboardReminder,
      //@ts-ignore
      customClass: 'smart-modal animate-left',
      props: {
        inModal: true,
        startDate: this.startDate,
        endDate: this.endDate,
        userId: this.$props.userId
      },
      parent: this,
    });
  }

  changePage() {
    this.page++;
    this.getReminders();
  }

  get showCreateReminder() {

    if(isFuture(this.$props.startDate) || (isFuture(this.$props.endDate) && isBefore(this.$props.startDate, new Date())))
    {
      return true;
    }

    return false;
  }

  get hasWorkspaceMembers(){
    if(this.$store.state.workspace.allMembers.length > 1){
      return true;
    }
    return false;

  }


  getReminders() {
    this.$apollo.query({
      query: GetMyRemindersOnDateRange,
      fetchPolicy: 'network-only',
      variables: {
        start_date: this.$props.startDate,
        end_date: this.$props.endDate,
        userId: this.$props.userId,
        page: this.page
      }
    }).then((res: any) => {
      if(this.reminderLists) {
        this.reminderLists.data = this.filterDuplicateData([...this.reminderLists.data, ...res.data.myRemindersOnDateRange.data]);
        this.reminderLists.paginatorInfo = res.data.myRemindersOnDateRange.paginatorInfo;
      }else{
        this.reminderLists = res.data.myRemindersOnDateRange;
      }

    
    })
  }

  filterDuplicateData(arr: Array<any>) {
    return arr.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i)
  }

  mounted() {
    this.page++
    this.getReminders();

    this.$events.listen('user_reminder_created', (_) =>{
      this.getReminders();
    })
  }

  createReminder() {
    let weeks = [];
    for (let i = this.startDate; isBefore(i, this.endDate); i = addDays(i, 1)) {
      if(isToday(i) || isFuture(i)){
        let oldDate = new Date(i);
        let newDate = new Date();
        oldDate.setHours(newDate.getHours());
        oldDate.setMinutes(newDate.getMinutes());
        oldDate.setSeconds(newDate.getSeconds());
        weeks.push({weekDayName: format(i, 'EEEE'), weekDay: oldDate})
      }

    }
    this.$buefy.modal.open({
      component: CreateReminder,
      parent: this,
      props: { showMemberLists: true, weekDates: weeks, isMasterDashboard: true},
      width: '480px',
    });
  }

  @Watch('startDate', {deep: true})
  startDateChange() {
    this.reminderLists = null;
    this.getReminders();
  }

  @Watch('userId', {deep: true})
  userIdChange() {
    this.reminderLists = null;
    this.getReminders();
  }
}
